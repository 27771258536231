import * as ROUTE_META from './data.json'
import *  as APP_CONFIG from '../config'
const metadata = ROUTE_META.metadata

export const headerLinks = [
    {
        internalLink:true,
        linkDetails:{
            label:metadata.home.linkName,
            url:metadata.home.route,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.services.linkName,
            url:metadata.services.route,
        }
    },

    {
        internalLink:true,
        linkDetails:{
            label:metadata.testimonials.linkName,
            url:metadata.testimonials.route,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.contact.linkName,
            url:metadata.contact.route,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.about.linkName,
            url:metadata.about.route,
        }
    }
]




const renderInternalNavLinks = () =>{

    //render each internal link that is in the headerLinks array
    let footerNavLinks =[]
    for (const navLink of headerLinks) {
        if(navLink.internalLink === true) footerNavLinks.push({value: {...navLink}, type:'link'})
    }
    // console.log(footerNavLinks)
    return footerNavLinks
}


export const footerContent = {
    columns:[
        {
            content:[
                {
                    type:'title',
                    value:'Harris Sparks Electrical'
                },
                ...renderInternalNavLinks()
            ]
        },
        {
            content:[
                {
                    type:'title',
                    value:'Our Services'
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Power Services',
                            url:'harris-sparks-electrical-services'
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Intercom Installation',
                            url:'harris-sparks-electrical-services'
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Electrical Lighting',
                            url:'harris-sparks-electrical-services'
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Electrical Maintenance',
                            url:'harris-sparks-electrical-services'
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Pay TV Installation',
                            url:'harris-sparks-electrical-services'
                        }
                    }
                }
            ]
        },
        {
            content:[
                {
                    type:'title',
                    value:'Our Services'
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Electrical Fault Finding',
                            url:'access-control-systems'
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Data and Voice Installation',
                            url:'harris-sparks-electrical-services'
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'CCTV Installations',
                            url:'harris-sparks-electrical-services'
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Fire Detection',
                            url:'harris-sparks-electrical-services'
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Power Services',
                            url:'harris-sparks-electrical-services'
                        }
                    }
                }
            ]
        },
        {
            content:[
                {
                    type:'img',
                    value:{
                        src: APP_CONFIG.FOOTER_IMAGE,
                        title: APP_CONFIG.WEBSITE_NAME,
                        alt: APP_CONFIG.WEBSITE_NAME
                    }
                }
            ]
        }
    ]
}
